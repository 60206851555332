<template>
  <div>
        <van-nav-bar
            title="消息"
        />
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">        
            <van-list                 
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了~"
                @load="onLoad" 
                class="user-poster"
                id='user-poster'>

                <van-cell class="user-root" @click="goDetail(index)" v-for="(p,index) in list" :key="index">
                <!-- 用户头像和id -->
                <div v-show="isCollect">
                    <div class="header">
                        <div class="user-head">
                            <a href="#">
                                <img :src="p.avatar" alt="">
                            </a>
                        </div>

                        <div class="user-name">
                            <div class="user-id">{{p.nickname}}</div>
                            <div class="user-time">{{p.created}}</div>
                        </div>            
                    </div>

                    <div class="user-content">
                        <a href="difficulty.html" style="color: dodgerblue;">#{{p.difficulty}}</a>
                            {{p.content}}
                    </div>

                    <div class="user-photo">
                        <img :src="p.pic" alt="" @click.stop="getImage(index)">
                    </div>
                    <div class="icons" >

                        <div style="display:flex">

                            <van-icon @click.stop="unCollectRoute(p,index)" v-show="isCollect" color="#FFD700" name="star" size="20"/>
                            <span v-show="!p.collectCounts">收藏</span>
                            <span style="font-size: 15px;" v-show="p.collectCounts">
                                {{p.collectCounts}}
                            </span>
                        </div>   


                        <div style="display:flex">
                            <van-icon style="margin-top:2px" name="comment-o" size="21"/>
                            <span v-show="!p.commentCounts">
                                评论
                            </span>
                            <span style="font-size: 15px;" v-show="p.commentCounts">
                                {{p.commentCounts}}
                            </span>
                            
                        </div>

                        <div style="display:flex">
                            <van-icon v-show="!isLike" name="good-job-o" size="22"/>
                            <span>点赞</span>
                            <van-icon v-show="isLike" color="#e93855" name="good-job"  size="22"/>
                        </div>   
                    </div>
                </div>

                </van-cell>  
            </van-list>
        </van-pull-refresh>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { ImagePreview } from 'vant';
export default {
    data() {
        return {
            loading: false,
            finished: false,
            refreshing: false,
            collections:[],
            collectionId:[],
            isLike: false,
            isCollect:true,
            userId:'',
            posterInfo:[],
            list:[],
            k:0,
            t:5
        }
    },

    methods:{

        getImage(index){
            ImagePreview([
                this.posterInfo[index].pic
            ]);
        },
        
        goDetail(index){
            this.$router.push({
                name:"detail",
                params:{
                    posterId: this.posterInfo[index].id
                }
            })
        },
        getUploading(ids){
            this.$axios.get("/blogs").then(
                res=>{
                    this.posterInfo = res.data.data.records
                    for (let k = 0; k < ids.length; k++) {
                        const element = ids[k];
                        for (let i = 0; i < this.posterInfo.length; i++) {
                            const element2 = this.posterInfo[i];
                            if(element==element2.id){
                                this.collectionId.push(this.posterInfo[i]);
                            }
                        }
                    
                        
                    }


                    this.collectionId.reverse()
                    this.onLoad()
                }
            )
        },

        collection(user_id){
            this.$axios.get("/collection/collects/"+user_id).then(
                res =>{
                    console.log(res.data.data)
                    const a = []
                    this.collections = res.data.data
                    this.collections.forEach(element => {
                        a.push(element.articleId)
                    });
                    this.getUploading(a)
                }
            )
        },



        unCollectCount(p){
            this.isCollect = !this.isCollect;
            p.collectCounts = p.collectCounts - 1
            this.$axios.post("/blog/editComments",{
                id: p.id,
                commentCounts : p.commentCounts,
                collectCounts: p.collectCounts,
                likeCounts:0
            })
        },


        unCollectRoute(p,index){
            this.$axios.post("/collection/cancelCollect",{
                articleId: p.id,
                userId : this.$store.getters.getUser.id
            }).then(
                res =>{
                    this.unCollectCount(p)
                    Toast("已取消")
                    
                }
            )
        },



        onLoad(ids) {
            setTimeout(()=>{
                if(this.refreshing){
                    this.list = []
                    this.k = 0
                    this.t = 5
                    this.refreshing = false
                }


                if(this.collectionId.length<this.t){
                    this.list = this.collectionId
                }else{
                    for (let i = this.k; i < this.k+5; i++) {
                        this.list.push(this.collectionId[i]);
                    }

                    this.k = this.k+5
                    this.t = this.t+5
                }

                this.loading = false;
                if (this.list.length >= this.collectionId.length) {
                    this.finished = true;
                }
                console.log(this.list)


            },1000)


        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },

    },

    mounted() {
        
        this.userId = this.$store.getters.getUser.id
        this.collection(this.userId)
        this.getUploading(this.userId)   
    },
}


</script>

<style scoped>
  
.user-poster {

    width:  100%;
    height: auto;
    box-sizing: border-box;
    background-color: white;
    color: black;
}

.user-root {
    /* border-radius: 25px; */
    overflow: hidden;
    border-top: solid 0.5px #e6e6e6;
}

.header{
    
    display: flex;
    height: 45px;
}

.user-head {
    width: 43px;
    height: 43px;
    /* background-color: rgb(68, 138, 138); */
}

.user-head img {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
}

.user-name {
    margin-left: 5px;
    color:black;
    font-weight: 700;
    font-size: 15px;
}
.user-time {
    color: #b7b7b7;
    font-size: 12px;
    margin: 2px;
}

.user-content {
    padding: 5px;
    color: black;
    margin-left: 45px;
    font-size: 17px;
    /* background-color: yellow; */
}

.user-photo{
    display: flex;
    margin-left: 45px;
    margin-bottom: 10px;
    width: 87%;
    /* background-color: wheat; */

}

.user-photo img{
    border:  0.5px solid rgb(168, 168, 168);
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    
}

.search-login a{
    text-decoration:none;
    color:black;
}

.icons{
    position: relative;
    z-index: 10;
    display: flex;
    height: 30px;
    margin: 0 auto;
    width: 85%;
    display: flex;
    right: 0;
}

.icons div{
    width: 33%;

    margin: 5px 20px 5px 10px ;
}


.icons i{
   margin-left: 10px;
    position: absolute;
}

.icons span{
    position: absolute;
    z-index: 10;
    margin-left: 40px;
    
}

</style>